import { Box, Typography, useTheme } from "@mui/material";

export default function PaintingTitle(params: { title: String }) {
    const theme = useTheme();

    return (
        <Box component="div" sx={{ paddingTop: "10px", display: "flex", alignItems: "bottom", justifyContent: "center" }}>
            <Typography gutterBottom variant="subtitle1" >
                {params.title}
            </Typography>
        </Box>
    );
}