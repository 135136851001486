import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Banner from "./Banner";

export default function Shop() {
    return (
    <Box sx={{ m:"auto", maxWidth:"300px", p:"20px", }}>
        Kommt noch
    </Box>
    );
}