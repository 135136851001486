import { Box, Button, IconButton, Menu, MenuItem, useMediaQuery } from "@mui/material";
import MainNavigationItem from "./MainNavigationItem";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useState } from "react";
import MainNavigationItemMobile from "./MainNavigationItemMobile";

export default function MainNavigationMobile() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ paddingBottom: "40px", paddingLeft: "30px" }}>
            <IconButton
                size="large"
                edge="start"
                onClick={handleClick}
            >
                <MenuIcon />
            </IconButton>
            <Menu  
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
            >
                    <MainNavigationItemMobile name="Über der Kunstraum" path="about" callback={handleClose}/>
                    <MainNavigationItemMobile name="Zur Künstlerin" path="artist" callback={handleClose}/>
                    <MainNavigationItemMobile name="Online-Galerie" path="gallery/all" callback={handleClose}/>
                    <MainNavigationItemMobile name="Künstlerbücher" path="" callback={handleClose}/>
                    <MainNavigationItemMobile name="Besuch/Kontakt" path="visit" callback={handleClose}/>
              </Menu>
        </Box>
    );
}