import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Banner from "./Banner";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";

export default function Visit() {
    const dispatch = useDispatch();
    dispatch(change("visit"));

    const isMobile = useMediaQuery('(max-width:600px)');

    const maxWidthBox = isMobile ? "80%" : "600px";
    const paddingTopBox = isMobile ? "0px" : "25px";
    const paddingLeftBox = isMobile ? "30px" : "0px";

    return (
        <Box sx={{ maxWidth: maxWidthBox, paddingTop: paddingTopBox, paddingLeft: paddingLeftBox }} >
            <Typography variant="subtitle1">
                Öffnungszeiten
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.925rem"}}>
                Nach Voranmeldung.
            </Typography>
            <Typography variant="subtitle1" sx={{ fontStyle: 'normal', marginTop: "20px" }}>
                Kontakt
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.925rem"}}>
                Email: contact@kunstraum-rudolfsplatz.at
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.925rem"}}>
                Tel.: +43 (0)681 81839553
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.925rem"}}>
                Website: <Link target="_blank" href="https://www.kunstraum-rudolfsplatz.at">www.kunstraum-rudolfsplatz.at</Link>
            </Typography>
            <Typography variant="subtitle1" sx={{ fontStyle: 'normal', marginTop: "20px" }}>
                Erreichbarkeit und Anfahrt
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.925rem"}}>
                Rudolfsplatz 3/Top 9
             </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.925rem"}}>
                1010 Wien
            </Typography>
            <Typography>
                <Link target="_blank" href="https://www.google.at/maps/place/Rudolfspl.+3,+1010+Wien/@48.214747,16.3694579,16z/data=!3m1!4b1!4m6!3m5!1s0x476d07a39b525807:0x97c3a6afcb445d35!8m2!3d48.2147435!4d16.3720328!16s%2Fg%2F11csn9znkn?hl=de&entry=ttu&g_ep=EgoyMDI0MDkyOS4wIKXMDSoASAFQAw%3D%3D">
                    Karte
                </Link>

            </Typography>
            <Typography sx={{ marginTop: "10px" }}>
            </Typography>
        </Box>
    );
}