import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import MainNavigationItem from "./MainNavigationItem";
import { Menu } from "@mui/icons-material";
import MainNavigationMobile from "./MainNavigationMobile";

export default function MainNavigation() {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div>
            {!isMobile &&
                <Box sx={{ paddingBottom: "40px" }}>
                    <MainNavigationItem name="Über der Kunstraum" path="about" />
                    <MainNavigationItem name="Zur Künstlerin" path="artist" />
                    <MainNavigationItem name="Online-Galerie" path="gallery/all" />
                    <MainNavigationItem name="Künstlerbücher" path="" />
                    <MainNavigationItem name="Besuch/Kontakt" path="visit" />
                    {false &&
                        <div>
                            <MainNavigationItem name="Virtuelle Tour" path="virtualtour" />
                            <MainNavigationItem name="Shop" path="shop" />
                        </div>
                    }
                </Box>
            }
            {isMobile &&
                <MainNavigationMobile/>
            }
        </div>
    );
}