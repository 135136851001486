import { Box, Fade, Paper } from "@mui/material";
import { Outlet } from "react-router-dom";
import Banner from "./Banner";
import { FadeIn } from "./Styles";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";
import { useEffect, useState } from "react";


export default function Home() {
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();

    useEffect( () => {
        const tick= setInterval(() => {setCount(count+1)}, 3000);
        return ()=>{
            clearInterval(tick);
          }
    });

    if (count === 0) {
         dispatch(change("home"));
    }
    
    const imgNr = ((count % 3) + 2).toString();
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "30px" }}>
            <img src={"/img/kr" + imgNr + ".png"} style={{ maxHeight: "50vh", maxWidth: "80vw"}} />
        </Box>
    );
}