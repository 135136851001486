import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from '../store';
import Banner from './Banner';
import Footer from './Footer';
import Logo from './Logo';
import MainNavigation from './MainNavigation';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Site() {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Box>
            {!isMobile &&
                <Box sx={{ minHeight: window.innerHeight, bgcolor: "kr.main" }}>
                    <Logo />
                    <Box sx={{ marginLeft: "20%", maxWidth: "60%" }}>
                        <MainNavigation />
                        <Outlet></Outlet>
                    </Box>
                </Box>
            }
            {isMobile &&
                <Box sx={{ minHeight: window.innerHeight, bgcolor: "kr.main" }}>
                    <Logo />
                    <MainNavigation />
                    <Outlet></Outlet>
                </Box>
            }
        </Box>
    );
}