import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Site from './component/Site';
import { changeLang } from './state/Language';
import { RootState } from './store';

import About from './component/About';
import Gallery from './component/Gallery';
import Home from './component/Home';
import PaintingCard from './component/Painting';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Virtual from './component/Virtual';
import Artist from './component/Artist';
import Shop from './component/Shop';
import Visit from './component/Visit';
import Test from './component/Test';

declare module '@mui/material/styles' {
  interface Palette {
    kr: Palette['primary'];
    active: string;
  }

  interface PaletteOptions {
    kr?: PaletteOptions['primary'];
  }

  interface Theme {
    painting: {
      landscape: {
        height: number,
        width: number,
      },
      portrait: {
        height: number,
        width: number,
      },
      title: {
        p: string,
      },
      p: string,
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    painting?: {
      landscape?: {
        height?: number;
        width?: number,
      },
      portrait?: {
        height?: number;
        width?: number,
      },
      title: {
        p: string,
      },
     p?: string,
    };
  }
}

function App() {
  const lang = useSelector((state: RootState) => state.language.value);
  const dispatch = useDispatch();
  dispatch(changeLang("EN"));

  const theme = createTheme({
    palette: {
      kr: {
        main: "#f4f2e8",
      }
    },
    painting: {
      landscape: {
        width: 853,
        height: 607,
      },
      portrait: {
        width: 607,
        height: 853,
      },
      title: {
        p: "0px"
      },
      p: "20px"
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Site />}>
          <Route path="" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="artist" element={<Artist />} />
          <Route path="visit" element={<Visit />} />
          <Route path="gallery/:serie" element={<Gallery />} />
          <Route path="virtualtour" element={<Virtual/>} />
          <Route path="shop" element={<Shop/>} />
          <Route path="paintings/:name" element={<PaintingCard />} />
        </Route>
        <Route path="test" element={<Test />} />
      </Routes>
    </ThemeProvider>
  );
  //  return <>{routing}</>;
}

export default App;
