import { Box, Breadcrumbs, Button, Divider, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import i18n, { getSeries } from "../i18n";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";
import { ArrowBack, ArrowUpward, ZoomIn } from "@mui/icons-material";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import PaintingMobile from "./PaintingMobile";

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}
function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
}


export default function Gallery() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    dispatch(change("gallery"));

    const isMobile = useMediaQuery('(max-width:600px)');

    let heading = "Die verschiedenen Bilderzyklen der Künstlerin sind jeweils einem thematischen Schwerpunkt gewidmet.";
    let itemData = [
        {
            name: "Serie_Zauberfloete",
            title: "Die Zauberflöte",
            image: "small/Koenigin_der_Nacht"
        },
        {
            name: "Serie_AdamUndEva",
            title: "Adam und Eva",
            image: "small/Adam_und_Eva4"
        },
        {
            name: "Serie_Exlibris",
            title: "Exlibris",
            image: "small/Hamlet"
        },
        {
            name: "Serie_Zirkus",
            title: "Zirkus",
            image: "small/Zirkus4"
        },
        {
            name: "Serie_Augustin",
            title: "Der Liebe Augustin",
            image: "small/Augustin2"
        },
        {
            name: "Serie_Heilige",
            title: "Heilige",
            image: "small/Heiliger_Nepomuk"
        },
        {
            name: "Serie_Pinocchio",
            title: "Pinocchio",
            image: "small/PinocchioDante3"
        },
        {
            name: "Serie_Bibel",
            title: "Biblisches",
            image: "small/Arche_Noah1"
        },
        {
            name: "Serie_Buchstaben",
            title: "Buchstäbliches",
            image: "small/Buchstabe_M"
        },
        {
            name: "Serie_Babylon",
            title: "Vermischtes",
            image: "small/Mondphasen"
        }
    ];
    if (params.serie != "all") {
        itemData.map((val) => {
            if (val.name === params.serie!) {
                heading = "Arbeiten aus dem Bilderzyklus '" + val.title + "'";
            }
        })
        itemData = getSeries(params.serie!);
    }
    const variant = (params.serie != "all") ? "standard" : "standard";

    const nrCols = isMobile ? 1 : 3;
    const maxWidthBox = isMobile ? "80%" : "800px";
    const paddingTopBox = isMobile ? "0px" : "25px";
    const paddingLeftBox = isMobile ? "30px" : "0px";
    const paddingImageList = isMobile ? "0px 30px" : "5px";

    return (
        <Box>
            {!isMobile &&
                <Box>
                    <Box sx={{ maxWidth: maxWidthBox, paddingTop: paddingTopBox, paddingLeft: paddingLeftBox }}>
                        <Breadcrumbs separator="›">
                            {params.serie != "all" &&
                                <Box>
                                    <Link component={RouterLink as any} to="/gallery/all" underline="none" color="inherit" href="/">
                                        <Typography variant="body2" sx={{ fontSize: "0.925rem", display: "block" }}>
                                            Bilderzyklen
                                        </Typography>
                                    </Link>
                                </Box>
                            }
                            <Typography variant="body2" sx={{ fontSize: "0.925rem", display: "block" }}>
                                {heading}
                            </Typography>
                        </Breadcrumbs>
                    </Box>

                    <Box sx={{}}>
                        <ImageList sx={{ padding: paddingImageList }} cols={nrCols} variant={variant} gap={45} >
                            {itemData.map((item) => (
                                <ImageListItem key={item.name} sx={{ boxShadow: 5 }}>
                                    <img
                                        src={"/img/" + item.image + ".jpg"}
                                        loading="lazy"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            if (params.serie === "all") {
                                                navigate("/gallery/" + item.name);
                                            }
                                            else {
                                                navigate("/paintings/" + item.name);
                                            }
                                        }}
                                    //                                onClick={() => {  }}
                                    />

                                    <ImageListItemBar position="below" sx={{ paddingTop: "10px", textAlign: "center", maxWidth: "100%", background: "white" }}
                                        title={item.title}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box >
                </Box>
            }
            {isMobile &&
                <Box>
                    <Box sx={{ maxWidth: maxWidthBox, paddingTop: paddingTopBox, paddingLeft: paddingLeftBox }}>
                        <Breadcrumbs separator="›">
                            {params.serie != "all" &&
                                <Box>
                                    <Link component={RouterLink as any} to="/gallery/all" underline="none" color="inherit" href="/">
                                        <Typography variant="body2" sx={{ fontSize: "0.925rem", display: "block" }}>
                                            Bilderzyklen
                                        </Typography>
                                    </Link>
                                </Box>
                            }
                            <Typography variant="body2" sx={{ fontSize: "0.925rem", display: "block" }}>
                                {heading}
                            </Typography>
                        </Breadcrumbs>
                    </Box>

                    <Box sx={{}}>
                        <ImageList sx={{ padding: paddingImageList }} cols={nrCols} variant={variant} gap={45} >
                            {itemData.map((item) => (
                                <ImageListItem key={item.name} sx={{ boxShadow: 5 }}>
                                    <img
                                        src={"/img/" + item.image + ".jpg"}
                                        loading="lazy"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            if (params.serie === "all") {
                                                navigate("/gallery/" + item.name);
                                            }
                                            else {
                                                navigate("/paintings/" + item.name);
                                            }
                                        }}
                                    //                                onClick={() => {  }}
                                    />

                                    <ImageListItemBar position="below" sx={{ paddingTop: "10px", textAlign: "center", maxWidth: "100%", background: "white" }}
                                        title={item.title}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box >
                </Box>
            }
        </Box>
    );
}