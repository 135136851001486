import { Box, Typography, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Banner from "./Banner";
import { useDispatch } from "react-redux";
import { change } from "../state/Nav";

export default function About() {
    const dispatch = useDispatch();
    dispatch(change("about"));

    const isMobile = useMediaQuery('(max-width:600px)');

    const maxWidthBox = isMobile ? "80%" : "600px";
    const paddingTopBox = isMobile ? "0px" : "25px";
    const paddingLeftBox = isMobile ? "30px" : "0px";
   
    return (
        <Box sx={{ maxWidth: maxWidthBox, paddingTop: paddingTopBox, paddingLeft: paddingLeftBox }}> 
            <Typography variant="subtitle1" sx={{fontStyle: 'normal'}}>
                Über den Kunstraum
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.925rem"}} >
                Der Kunstraum Rudolfsplatz vermittelt im Rahmen einer begleiteten Dauerausstellung in historischen Räumlichkeiten am Rudolfsplatz in Wien
                das über mehr als zwei Jahrzehnte entstandene Oeuvre der russisch-österreichischen bildenden Künstlerin Anna Laour.
                Durch den ergänzenden und unterstützenden intensiven Einsatz von Techniken der Kunstvermittlung, wie sie sonst nur im musealen Bereich anzutreffen sind,
                werden die Werke der Künstlerin in besonderer Weise erlebbar und erfahrbar gemacht.
            </Typography>
            <Typography variant="subtitle1" sx={{ fontStyle: 'normal', marginTop: "20px" }}>
                Zum Ausstellungsort
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.925rem"}}>
                Das Areal des heutigen, 1862 nach Kronprinz Rudolf benannten Rudolfsplatzes war ursprünglich von einem der Donauarme, dem sog. „Salzgriesarm“, bedeckt. Durch die zunehmende Versandung des Flusses konnte im Mittelalter auf dem so gewonnenen Land die unmittelbar außerhalb der Stadtmauern gelegene Siedlung „Vorstadt vor dem Werdertor“ gegründet werden. Nachdem der Salzgriesarm endgültig seine Schiffbarkeit verloren hatte, wurde er zugeschüttet, und auf dem Gelände entstanden ab dem 16. Jh. neuen Festungsanlagen sowie das davor liegende Glacis. Im Zuge der Stadterweiterung des 19. Jh. wurde das veraltete Ver-teidigungssystem 1857 geschliffen, und die nachfolgende gründerzeitliche Bebauung gab dem Rudolfsplatz und seiner Umgebung im Wesentlichen sein heutiges Aussehen. Die noch immer gebräuchliche Bezeichnung „Textilviertel“ für diesen Bereich der Wiener Innenstadt rührt von den zahlreichen, oft jüdischen Stoff- und Kleidungshändler her, die sich um die Jahrhundertwende hier ansiedelten.
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "10px", fontSize: "0.925rem" }}>
                Im Zentrum des Platzes liegt der ca. vier Tausend Quadratmeter große Rudolfspark, eine grüne Oase inmitten des nördlichen Teils der Innenstadt.
                Umsäumt wird der Park von einer Reihe imposanter Zinshäuser, die ab dem Jahre 1861 erbaut wurden.
                So auch das vom Architekten Anton Baumgarten gestaltete Gebäude Rudolfsplatz 3, in dem der Kunstraum Rudolfsplatz untergebracht ist.
            </Typography>
        </Box>
    );
}