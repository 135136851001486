import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Banner from "./Banner";

export default function Virtual() {
    window.open("https://annalaour.com/",)

   //window.open("/virtual/virtualtour.html",)
    return (
        <div></div>
    );
}