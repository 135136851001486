
import { Box, Button, ButtonGroup, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';
import { GlassMagnifier, Magnifier } from 'react-image-magnifiers';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { createRef, useEffect, useRef, useState } from 'react';
import PaintingTitle from './PaintingTitle';
import Video from './Video';
import i18n from '../i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { Close, Fullscreen, OndemandVideo, ZoomIn } from '@mui/icons-material';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { TransformWrapper, TransformComponent, useTransformComponent } from 'react-zoom-pan-pinch';

export default function Painting() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const handle = useFullScreenHandle();
    const params = useParams();
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(isMobile);
    const x = i18n(params.name!, "de");

    const [mode, setMode] = useState((x.hasVideo === "true") ? "video" : "image");
    const [description, setDescription] = useState("");
    const nav = useNavigate();
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    /*
    useEffect(() => {
        fetch("/data/de/" + params.name + ".txt")
            .then(response => response.text())
            .then(textString => {
                setDescription(textString);
            });
    });
    */
    useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    const lang = useSelector((state: RootState) => state.language.value);

    let imagePath = "/img/small/" + params.name + ".jpg";
    let largeimagePath = "/img/large/" + params.name + ".jpg";
    let videoPath = "/video/de/" + params.name + ".mp4";

    let p = theme.painting.p;
    let w = theme.painting.landscape.width;
    let h = theme.painting.landscape.height;
    if (x.format === "portrait") {
        w = theme.painting.portrait.width;
        h = theme.painting.portrait.height;
    }

    const fullsize = handle.active;
    
    if (fullsize || h > window.visualViewport!.height || w > window.visualViewport!.width) {
        if (w * window.visualViewport!.height > h * window.visualViewport!.width) {
            h = h * window.visualViewport!.width / w;
            w = window.visualViewport!.width;
        }
        else {
            w = w * window.visualViewport!.height / h;
            h = window.visualViewport!.height;
        }
        p = "0px";
    }
 
    const ws = w.toString() + "px";
    const hs = h.toString() + "px";

    return (
        <Modal open={open} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <FullScreen handle={handle}>
                {!fullsize &&
                    < Box sx={{ bgcolor: 'kr.main', p: p }}>
                        {mode === "video" &&
                            <Box sx={{ width: w, height: h, boxShadow: "5" }}>
                                <Video videoSrc={videoPath}></Video>
                            </Box>
                        }
                        {mode === "image" &&
                            <Box sx={{ margin: "auto", display: "flex", alignItems: "center", justifyContent: "center", boxShadow: "5" }}>
                                <TransformWrapper>
                                    <TransformComponent>
                                        <img src={largeimagePath} alt="test" style={{ margin: "auto", width: "auto", maxHeight: h }} />
                                    </TransformComponent>
                                </TransformWrapper>
                            </Box>
                        }
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box />
                            <PaintingTitle title={x.title} ></PaintingTitle>
                            <Box component="div" sx={{ paddingTop: theme.painting.title.p, display: "flex", justifyContent: "right" }}>
                                <ButtonGroup variant="contained" aria-label="Basic button group">
                                    {mode === "video" &&
                                        <IconButton size="small" onClick={() => { setMode("image") }}>
                                            <ZoomIn></ZoomIn>
                                        </IconButton>
                                    }
                                    {mode === "image" && x.hasVideo === "true" &&
                                        <IconButton size="small" onClick={() => { setMode("video") }}>
                                            <OndemandVideo></OndemandVideo>
                                        </IconButton>
                                    }
                                    <IconButton size="small" onClick={() => { handle.enter() }}>
                                        <Fullscreen></Fullscreen>
                                    </IconButton>

                                    <IconButton size="small" onClick={() => { setOpen(false); nav("/gallery/Serie_" + x.serie) }}>
                                        <Close></Close>
                                    </IconButton>
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </Box>
                }
                {fullsize &&
                    < Box sx={{ bgcolor: 'kr.main', p: p }}>
                        {mode === "video" &&
                            <Box sx={{ width: "100vw", height: "100vh", bgcolor: 'kr.main' }}>
                                <Video videoSrc={videoPath}></Video>
                            </Box>
                        }
                        {mode === "image" &&
                            <Box sx={{ margin: "auto", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <TransformWrapper initialPositionX={(window.visualViewport!.width - w) / 2} initialPositionY={(window.visualViewport!.height - h) / 2}>
                                    <TransformComponent wrapperStyle={{ width: "100vw", maxHeight: "100vh" }}>
                                        <img src={largeimagePath} alt="test" style={{ margin: "auto", width: "auto", maxHeight: h }} />
                                    </TransformComponent>
                                </TransformWrapper>
                            </Box>
                        }
                    </Box>
                }
            </FullScreen >
        </Modal >
    );
}


