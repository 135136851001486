import { AppBar, Box, Button, ButtonBase, Modal, Toolbar, Typography, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Banner from "./Banner";
import Footer from "./Footer";
import Logo from "./Logo";
import MainNavigation from "./MainNavigation";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { TransformWrapper, TransformComponent, useTransformComponent } from "react-zoom-pan-pinch";
import Video from "./Video";
import { useState } from "react";

export default function Test() {
    const theme = useTheme();
    const handle = useFullScreenHandle();

    let w = theme.painting.portrait.width;
    let h = theme.painting.portrait.height;

    if (w * window.visualViewport!.height > h * window.visualViewport!.width) {
        h = h * window.visualViewport!.width / w;
        w = window.visualViewport!.width;
    }
    else {
        w = w * window.visualViewport!.height / h;
        h = window.visualViewport!.height;
    }
    const p = "0px";
    const imgSrc = handle.active ? "/img/large/Augustin2.jpg" : "";
    const display = handle.active ? "block" : "none";

    const heightBox = handle.active ? "80vh" : "100vh";
    return (
        <FullScreen handle={handle}>
            <Box sx={{ bgcolor: 'green', p: p, display: "flex", justifyContent: "center", alignItems: "center", width: window.visualViewport!.width, height: window.visualViewport!.height }}>
                <TransformWrapper>
                    <TransformComponent wrapperStyle={{ width: "100vw", maxHeight: "100vh" }}>
                        <img src={imgSrc} style={{ display: display, margin: "auto", width: "auto", maxHeight: "100vh" }} />
                    </TransformComponent>
                </TransformWrapper>
            </Box>
            {!handle.active &&
                <Button onClick={() => { if (handle.active) { handle.exit() } else { handle.enter() } }}>Click</Button>
            }
        </FullScreen>
    );
}